// SlideCard.scss

.slide-card {
    display: flex;
    position: fixed; // 固定位置に配置
    bottom: 50px;    // 下端から50pxの位置
    right: 0;
    z-index: 100; 
    transform: translateX(calc(100% - 65px));
    transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  
    &__left {
        width: 65px;
        background: #76CED5;
        border-radius: 20px 0px 0px 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
      p{
        writing-mode: vertical-rl; 
        letter-spacing: 10.1px;
        color: #FFF;
      }
      
    }
  
    &__right {
        width: calc(100% - 65px); // 幅は固定とします。
        overflow: hidden;
        background: #E9F7F9;
      
        &.is-active {
        }
      }
  
    &.is-active {
        transform: translateX(0%);  
    }

    .reception{
        padding:17px 30px 35px;
        p{
            margin:0;
        }

        &__phone{
            text-align:left;
            color: #008FB5;

                &__number{
                    font-size: 40px;

                    &--pack{
                        margin-top:-15px;
                    }

                    span{
                        font-size: 16px;
                    }
                }
        }

        &__info{
            background:white;
            padding:7px 64px;
            border-radius: 50px;
            margin:10px;

            span{
                color: #008FB5;
            }
        }

        &__time{
            display:flex;
            flex-direction: column;
            gap: 15px;

            &__caption{
                text-align: left;
                font-size:12px;
            }


            &__box{            
                display:flex;
                gap:12px;

                &--blue{
                    color:#008FB5;
                }
            }
        }

        &__table{
            margin-top:10px;
            border-collapse: collapse;
            border:2px solid #008FB5;
            border-radius: 15px;
            th{
                padding:8px 15px;
                margin:0;
            }
            &--first{
                border-radius: 0px 0px 0px 15px;
            }
            &--last{
                border-radius: 0px 0px 15px 0px;
            }
            td{
                background: white;
                padding:10px 15px;
            }
            
        }
    }
  }


.slide-cardsp{
    position: fixed;
    bottom: 0;
    left:0;
    width:100%;
    z-index: 100; 
    transform: translateY(calc(100% - 44px));
    transition: all 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
    

    &__top {
        background: #76CED5;
        border-radius: 20px 20px 0px 0px;
      p{
        color: #FFF;
        margin: 0;
        margin: 0;
        padding: 10px 0 10px;
        text-align: center;
      }
      
    }
  
    &__bottom {
        width: 100%; // 幅は固定とします。
        overflow: hidden;
        background: #E9F7F9;
      
        &.is-active {
        }
      }
  
    &.is-active {
        transform: translateX(0%);  
    }

    .reception{
        padding:17px 30px 35px;
        p{
            margin:0;
            font-size: 12px;
        }

        &__phone{
            text-align:left;
            color: #008FB5;

                &__number{
                    font-size: 40px;

                    &--pack{
                        margin-top:-15px;
                    }

                    span{
                        font-size: 16px;
                    }
                }
        }

        &__info{
            background:white;
            padding:7px 64px;
            border-radius: 50px;
            margin:10px;

            span{
                color: #008FB5;
            }
        }

        &__time{
            display:flex;
            flex-direction: column;
            gap: 2px;

            &__caption{
                text-align: left;
                font-size:12px;
            }


            &__box{            
                display:flex;
                gap:12px;

                &--blue{
                    color:#008FB5;
                }
            }
        }

        &__table{
            margin-top:10px;
            border-collapse: collapse;
            border:2px solid #008FB5;
            border-radius: 15px;
            overflow: scroll;

            &::-webkit-scrollbar{
            }
            th{
                padding:10px 15px;
                margin:0;
                font-size: 12px;
            }
            &--first{
                border-radius: 0px 0px 0px 15px;
                font-size: 12px;
            }
            &--last{
                border-radius: 0px 0px 15px 0px;
                font-size: 12px;
            }
            td{
                background: white;
                padding:12px;
                font-size: 12px;
            }
            
        }
    }
  }

  