.firstimpression{
    text-align:left;

    .container-box__text{
        
        padding:30px 50px;
    }
    h3{
       color:rgba(255, 0, 0, 0.752); 
    }

    .button_wrapper{
        display:flex;
        gap:20px;
    }

    .image-links__button{

        padding:10px 20px;
        box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 40px;
    }

}