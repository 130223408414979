.dentalgeneral{
    .main{
        margin: 100px 0;
        &-contentsbox{
            display: flex;
            padding:0 50px;
            gap:60px;
            .imgwrapper{
               width:50%;

                img{
                    border-radius: 55px;
                }
            }

        }

        &-textwrapper{
            text-align: left;
            h4{
                color: #008FB5;
            }

        }
    }
}

@media (max-width: 910px){

    .dentalgeneral{

        .main{

            padding: 10px;

            &-contentsbox{
                flex-direction: column;
                padding:0 10px;
                gap:10px;

                .imgwrapper{
                    width:100%;
                }
            }
        }
    }
}