.dentalintroduction{

    .gallery{
        padding:0 80px;

        .title{
            padding: 110px 0;
        }

        &-contents{
            display: flex;
            gap:80px;
            justify-content: center;

            .imgbox,.textbox{
                width:50%;
                display: flex;
                justify-content: center;
                flex-direction: column;

            }

            .imgbox{
                img{
                    border-radius: 55px;
                }
            }
        }
    }

    @media (max-width: 910px){
        .gallery{
            padding:0 30px;
    
            .title{
                padding: 110px 0;
            }
    
            &-contents{
                display: flex;
                flex-direction: column;
                gap:0px;
                justify-content: center;
                align-items: center;
    
                .imgbox,.textbox{
                    width:100%;
                }
    
                .imgbox{
                    img{
                        border-radius: 10px;
                    }
                }
            }
        } 
    }


    .equipment{
        border-radius:0px 120px;
        background: #E9F7F9;
        margin:50px;
        padding:80px 50px;

        &-white{
            border-radius: 20px;
            background: #FFF;
            display: flex;
            gap:30px;
            padding:40px;
        }

        &-2{
            margin-top:80px;

            &-white{
                border-radius: 20px;
                background: #FFF;
                display: flex;
                flex-direction: column;
                gap:30px;
                padding:40px;
                @media (max-width: 910px){
                    padding:20px;

                }

                .flex-container{
                    display: flex;
                    gap:60px;
                    @media (max-width: 910px){
                        flex-direction: column;
                        align-items: center;
                        gap:20px;
                    }

                    .text-wrapper{
                        width:60%;

                        @media (max-width: 910px){
                            width:100%;
                        }

                        p{
                            text-align: left;
                        }

                    }
                    .imgwrapper{
                        width:40%;

                        @media (max-width: 910px){
                            width:100%;
                        }
                        img{
                            border-radius: 55px;
                            @media (max-width: 910px){
                                border-radius: 10px;
                            }
                        }
                    }
                }
            }

        }
    }

    @media (max-width: 910px){
        .equipment{
            border-radius:0px 40px;
            background: #E9F7F9;
            margin:10px;
            padding:30px 20px;

        }
    }



}