p{
    text-align: left;
}

.main{
    padding:40px;

    .title{
        margin-top:80px;
    }
}

.textbox2{
    margin-top:120px;
}

h3{
    text-align: left;
}