.specialize{

    &-title{
        margin:70px 0 60px;
    }

    &-contents{
        display: flex;
        justify-content: center;
        gap: 60px;
        padding:0 30px;

        .img-wrapper{
            width:50%;
            img{
                border-radius: 55px;
                height: 100%;
                object-fit: cover;
            }
        }
        p{
            font-size: 16px;
            text-align:left;
            line-height:30px;
            letter-spacing: 5px;
        }
    }

    .profit{
        display: flex;
        gap:120px;
    }


}