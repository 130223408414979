.medicalinformation{
    
    background-color: #E9F7F9;
    margin:0 40px;
    border-radius: 117px 0px;
    padding:90px 35px;

    .column{
        margin: 40px 0;
        background-color: white;
        border-radius: 55px;
        padding:15px 30px;
        text-align: left;
    }


    .others{

        .wrapper{
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 40px;
            column-gap: 20px;

            .box1,.box2,.box3,.box4{
                background: #FFF;
                border-radius: 54px;
                padding: 60px 0 50px 0;

                h3{
                    text-align: center;
                }
            }

            .box1,.box3,.box4{
                p{
                    padding:40px;
                }
            }
        }
    }


}

@media (max-width: 910px){
    .medicalinformation{
        padding:70px 20px;
        margin:0 10px;

        .others{

            .wrapper{
                grid-template-columns: 1fr; 

                h3{
                    text-align: center;
                }

            }
        }
    }
}