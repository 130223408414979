.imgtitlecontainer{
    height: 200px;
}

span{
    font-size: 24px,
}

@media (max-width: 910px){
    span{
        font-size:20px,
    }

    .imgtitlecontainer{
        height: 120px;
    }
}