.departments{
    background: #E7ECF0;
    padding:55px 0 90px;
    position: relative;
    z-index: 1;
    margin-top:70px;

    &__buttonbox{
        display: grid;
        grid-template-columns: repeat(4, minmax(0, 205px)); 
        gap: 20px;  /* 各アイテムの間隔。必要に応じて調整可能 */
        z-index:100;
        justify-content: center;
        margin:auto;
        position:relative;

        img{
            filter: brightness(1);
        }
        @media (max-width: 910px){
            grid-template-columns: repeat(2, minmax(0, 205px));
        }

    }


    &__img{
        z-index:1;
        
        &--topleft{
            position: absolute;
            top: 0;
            left: 0;
            width:auto;
        }
        &--bottomright{
            position: absolute;
            bottom: 0;
            right: 0;
            width:auto;
        }

    }

    
}

@media (max-width: 910px){
    .departments{
        .departments__img--topleft,.departments__img--bottomright{
            display: none;
        }

        &__buttonbox{
            grid-template-columns: repeat(2, minmax(0, 170px));
        }
    }
}