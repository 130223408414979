.medicalsurgery{

    .main{

        .box{
            display: flex;
            justify-content: center;
            gap:85px;
            margin:100px 120px;
            
            .img{
                width: 50%;
            }

            p{
                text-align: left;
                width: 50%;
            }
        }

        .fever{
            padding:30px 60px;
            background: #E9F7F9;
            margin: 0 70px 130px;
            border-radius: 60px 0;


            p{
                text-align: left;
            }
        }
    }
}

@media (max-width: 910px) {
    .medicalsurgery{
        .main{
            padding:20px;
            
            .box{
                flex-direction: column;
                margin:10px 10px;
                align-items: center;
                gap:10px;
            }
        }

    }
}