.informationsites{

    .main{
        background-color: #e9f7f9;
        border-radius: 117px 0;
        margin: 40px 40px;
        padding: 90px 35px;
    }

    section {
        margin:40px auto;
    }

    h3{
        margin: 0;
    }
    p{
        margin:0;
    }
    .box{
        border: 1px solid #000;
        border-radius: 20px;
        padding: 20px;
        margin-top: 20px;

        .red{
            font-size: 18px;
        }
    }
}


@media (max-width: 910px) {
    .informationsites{
        .main{
            border-radius: 30px;
            margin: 10px 10px;
            padding: 10px 20px;
        }

        section {
            margin:20px auto;
        }

        h3{
            font-size: 18px;
        }

        p{
            font-size:14px;
        }

        .box{
            border-radius: 20px;
            padding: 10px;
    
            .red{
                font-size: 14px;
            }
        }
    }
}