.greeting{
    &-title{
        margin:80px 0 50px;
    }
    &-contents{
        display: flex;
        gap:70px;
        padding:0 100px;
        margin-bottom:100px;

        .text-wrapper{
            width:50%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;


            p{
                text-align: left;
            }
            .famous{
                text-align: right;
                margin:0;
            }
        }

        .imgwrapper{
            width:50%;

            img{
                height: 100%;
                border-radius: 55px;
                object-fit: cover;
            }
        }
    }
    .greetings{
        padding:60px 0 50px;

        &__profilebox{
            display:flex;
            justify-content: center;
            gap:50px;
    
    
            img{
                width:100%;
            }
        }

        &__profile-table{
            text-align:left;
            border:solid 1px #008FB5 ;
            border-radius: 10px;
            max-width:520px;
            
    
            tr:nth-child(1),
            tr:nth-child(2){
              td {
                border-bottom: 1px solid #B6B6B6;
              }
            }
    
            td{
            }
    
            &--left{
                background: #E9F7F9;
                color: #008FB5;
            }
            &--right{
                background: #fff;
                padding-right: calc(120px * (100vw - 940px) / (1440px - 940px));
    
                @media (max-width: 940px) {
                    padding-right: 0;
                  }
                
                  @media (min-width: 1440px) {
                    padding-right: 120px;
                  }
    
            }
        }

        &__profile-img{
            max-width:520px;
        }
    }
}