.dental-symptoms{
    .contents{
        .title{
            margin-top:80px;
        }
    }
    .container{
        display: flex;
        justify-content: center;
        gap:100px;
        margin:120px 100px 0px;

        &.implantconteiner{
            gap:0px;
            margin-bottom:100px;
        }

        &-img{
            max-width: 450px;

        }

        &-box{
            display:flex;
            flex-direction: column;
            gap:70px;

            &__text{
                text-align:left;
                letter-spacing:5px;
                line-height: 50px;

                &.implanttext{
                    text-align:center;
                }
            }
        }

        .image-links__button{

            padding:20px 80px;
            box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
            border-radius: 40px;
        }

        .implant{
            display: flex;
            gap:20px;
            justify-content: center;


            .imgwrapper{
                img{
                    border-radius: 50px;
                    filter: brightness(1);
                }

            }
        }
    }
}

.correction_contents{
    padding:0 100px;
    margin-bottom:50px;
    .information{
        margin-top: 30px;
        p{
            text-align:center;
        }
    }
    .title{
        margin-top:80px;
    }
    .maincontents{
        display: flex;
        

        .video{
            width: 80%;
            video{
                width: 90%;
            }
        }

        .buttons{
            display:flex;
            width:40%;
            justify-content: center;
            margin: auto;

            .play{
                background: none;
                border:none;
                &_img{
                }
            }
            .stop{
                background: none;
                border:none;
                padding-left: 30px;
            }
            .replay{
                background: none;
                border:none;
            }

        }

        .texts{
            text-align: left;

            h3{
                margin-top: 0;
            }
        }

        .teethrow{
            
        }
    }
}

@media (max-width: 910px) {
    .dental-symptoms{
        .container{
            flex-direction: column;
            margin:10px 20px 0px;

            &-box{

                &__text{
                    text-align: center;
                }
            }
        }

    }

    .correction_contents{
        padding:0 15px;
        
        .title{
            margin-top:30px;
        }
    }
    .maincontents{
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .texts{
            margin-top:30px;
            h3{
                text-align:center;
            }
        }
    }
}