.dental-top{
    .key-visual {
        margin:35px 0;
        &__flex{
            display:flex;
        }
        &__links {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            padding:105px 0 105px 45px;
            a {
                padding: 20px 50px;
                white-space: nowrap;
                font-size: 20px;
            }
        }
    
        &__visual {
            border-radius: 54px 0 0 54px;
            overflow: hidden; 
            width: 100%;
        }
    }

    .news{
        padding:50px 0 120px;
        &__wrapper{
            display:flex;
            gap:70px;
            justify-content: center;
    
    
            img{
                width:100%;
                max-width: 630px;
                border-radius: 60px;
            }
        }

        &__content{
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            max-width: 440px;
            
            p{
                text-align: left;
                letter-spacing: 6.2px;
                line-height: 40px;
            }
        }

        .for-sp{

        }
    }

    .departments{
        background: #E7ECF0;
        padding:55px 0 90px;
        position: relative;
        z-index: 1;

        &__buttonbox{
            display: grid;
            grid-template-columns: repeat(4, minmax(0, 205px)); 
            gap: 20px;  /* 各アイテムの間隔。必要に応じて調整可能 */
            z-index:100;
            justify-content: center;
            margin:auto;
            position:relative;
            @media (max-width: 910px){
                grid-template-columns: repeat(2, minmax(0, 205px));
            }

        }


        &__img{
            z-index:1;
            &--topleft{
                position: absolute;
                top: 0;
                left: 0;
                width:auto;
            }
            &--bottomright{
                position: absolute;
                bottom: 0;
                right: 0;
                width:auto;
            }

        }

        
    }

    .greetings{
        padding:60px 0 50px;

        &__profilebox{
            display:flex;
            justify-content: center;
            gap:50px;
    
    
            img{
                width:100%;
            }
        }

        &__profile-table{
            text-align:left;
            border:solid 1px #008FB5 ;
            border-radius: 10px;
            max-width:520px;
            
    
            tr:nth-child(1),
            tr:nth-child(2), 
            tr:nth-child(3){
              td {
                border-bottom: 1px solid #B6B6B6;
              }
            }
    
            td{
            }
    
            &--left{
                background: #E9F7F9;
                color: #008FB5;
            }
            &--right{
                background: #fff;
                padding-right: calc(120px * (100vw - 940px) / (1440px - 940px));
    
                @media (max-width: 940px) {
                    padding-right: 0;
                  }
                
                  @media (min-width: 1440px) {
                    padding-right: 120px;
                  }
    
            }
        }

        &__profile-img{
            max-width:520px;
        }
    }

    .recruitment {
        padding:20px 167px 154px;

        &__text{
            // display: flex;
            // flex-direction: column;
            // justify-content: space-between;
            p{
                text-align: left;
            }
        }
  
          &__image{
            max-width: 450px;
          }
  
        &__flex{
          display: flex;
          justify-content: center;
          gap:66px;
          margin-top:53px;
          @media (max-width: 910px){
            
            flex-direction: column;
            }
        }
      }
    
    .facility{

        &__grid{
            display:grid;
            grid-template-rows: 175px 60px 175px;
            grid-template-columns: repeat(4, 1fr);
            gap:25px;
            max-width:900px;
            justify-content: center;
            align-items: center;
            margin:auto;
            position:relative;            
    
            img{
                width:100%;
                height: 100%;
                object-fit: cover;
            }
    
            &--1{
                order:1;
                grid-column: 1 / 2;
                grid-row: 1 / 3;
                z-index: 100;
                
            }
    
            &--2{
                order:2;
                grid-column: 1 / 2;
                grid-row: 3 / 4;
                z-index: 100;
            }
    
            &--3{
                order:3;
                grid-column: 2 / 3;
                grid-row: 1 / 2;
                z-index: 100;
            }    
    
            &--4{
                order:4;
                grid-column: 2 / 4;
                grid-row: 2 / 4;
                z-index: 100;
            }
    
            &--5{
                order:5;
                grid-column: 3 / 4;
                grid-row: 1 / 2;
                z-index: 100;
            }    
    
            &--6{
                order:6;
                grid-column: 4 / 5;
                grid-row: 1 / 4;
                z-index: 100;
            }

            &-vector{
                width: calc(940px + (100 * (100vw - 940px) / (1440 - 940)));
                max-width:1040px;
                min-width:940px;
                background: #76CED5;
                height: 490px;
                position:absolute;
                top:33px;
                z-index:1;
                border-radius: 116px 0px;
                left: 50%;
                transform: translateX(-50%);
            }    
        }
    }

@media (max-width: 910px) {
    .for-sp{
        .key-visual{
            width:100%;

            &__visual {
                width: 92%;
            }
        }

        .news{
            padding:30px 20px 20px;

            &__wrapper{
                flex-direction: column;
                gap:20px;

            }

        }

        .departments{
            padding: 15px 20px 40px;
        }

        .recruitment{
            padding: 30px 20px;


        }

        .greetings__profilebox{
            flex-direction: column;
        }
    }
}

}