.treatmentschedule{
    padding:0 88px;
    margin:100px 0 140px;

    .main{

        .schedule{
            margin-bottom:60px;

            &__title{
                color:#008FB5;
                letter-spacing: 14.14px;
                font-size: 28px;
                padding:20px 0 40px;
            }
            
            &__contents{
                text-align:left;

                &__textbox{
                    font-size: 18px;
                    margin-bottom:40px;

                    &__caption{
                        margin:0;
                        color: #008FB5;

                        .red{
                            color: #BD0000;
                        }
                    }

                    p{
                        margin:0;
                        font-size: 18px;
                    }
                }

                &__time{

                    &__title{
                        font-size: 26px;
                        color: #008FB5;
                    }
                    &__wrapper{
                        border: 2px solid #008FB5;
                        display: flex;
                        border-radius: 13px;
                        overflow: hidden;
                        max-width: 477px;
                         
                        p{
                            margin:0;
                            font-size: 18px;
                        }


                        &__subject{
                            background: #E9F7F9;
                            flex: 1;
                            max-width:140px;
                            padding:18px 14px;
                            color: #008FB5;

                        }

                        &__contents{
                            color: #008FB5;
                            padding:20px;
                        }

                    }
                }


                &__treatmentschedule{

                    .table-container {
                        border: 2px solid #008FB5;
                        border-radius: 15px;
                        overflow: hidden;
                      }
                      
                      .table-row {
                        display: flex;
                        justify-content: space-between; 
                      }
                      
                      .table-header {
                        background: #E9F7F9;
                        flex: 1;
                        padding: 20px 40px;
                        text-align: center;
                      }
                      
                      .table-cell {
                        flex: 1;
                        padding: 20px 40px;
                        text-align: center;
                        align-items: center;
                        margin:auto;

                        &.red{
                            color:red;
                        }
                        
                      }

                      &__caption{
                        margin-top:25px;
                      }
                }
            }
        }

        .closedday{
            padding:150px 0;
            border-top: 1px solid #CDCDCD;
            border-bottom: 1px solid #CDCDCD;

            &__title{
                color:#008FB5;
                margin:0;
                font-size: 48px;
                letter-spacing: 24.24px;
                text-align: center;
    
                &--sub{
                    color: #008FB5;
                    margin:0;
                    font-size: 30px;
                    text-align: center;
                    letter-spacing: 15.15px;
                }
            }
    
            &__text{
                margin-top:70px;
                letter-spacing: 6.2px;

                br {
                    margin-top: 30px;
                    margin-bottom: 30px;
                    content: " ";
                    display: block;
                    height: 0;
                  }
            }
    
            .line{
                position: absolute;
                height: 2px;
                background: #CDCDCD;
                top:20px;
            }
        }

    }

    




}

@media (max-width: 910px) {

    .treatmentschedule{
        padding:10px;
        margin:10px 0 10px;

        .main{

            .schedule{
                &__contents{
        
                    &__treatmentschedule{

        
                        .table-container{
                            overflow:scroll;
                        }

                        .table-header{
                            padding: 20px 20px;
                            font-size: 12px;

                            &:first-child{
                                padding: 20px 36px;
                            }
                        }

                        .table-cell{
                            padding: 20px 20px;
                            font-size: 12px;
                        }
        
                    }
                }
            }

        }
    }
}


