.slider-container{
  position: relative;

  .slick-slide {
    div {
      height: 605px;
        img {
          width:100%;
          height:100%;
          object-fit: cover;
          
            
        }
    }
  }      

  .slider-buttons{
    position: absolute;  // 絶対位置を設定
    bottom: 40px;        // 下から40pxの位置
    transform: translateX(-50%);
    left: 50%;  
    z-index: 1;
  }


      .slider-button {
        background-color: #76CED5; // デフォルトのボタン色
        border: none;
        border-radius: 50%; // ボタンを円形にする
        width: 15px; // 円のサイズ調整
        height: 15px;
        margin: 0 5px; // ボタン間のスペース

        &.active {
          background-color: #008FB5; // アクティブなボタンの色
        }
      }
  


}