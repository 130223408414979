.specialtreatment{
    padding:55px 100px;
    
    &__box{

        &__list{

            display:flex;
            justify-content: center;
            gap:20px;

            &__wrapper{

                &__textbox{
    
                    p{
                        position: relative;
                        display: inline-block;
    
                        &::after {
                            content: "";
                            position: absolute;
                            bottom: 0;
                            left: 25%; // テキストの左端から25%の位置にアンダーラインの開始点を置く
                            width: 50%; // アンダーラインの長さをテキストの50%に設定
                            height: 2px; // アンダーラインの高さ。必要に応じて調整可能
                            background-color: #87C9D1;
                            bottom:-6px;
                          }
    
                    }
    
                }

                &__imgwrapper{
                    height:100%;
                    width:210px;
                    
                    img{
                        object-fit: cover; 
                        height:100%;
                        width:100%;
                    }
                }
    
            }


        }



    }


}

@media (max-width: 910px){
    .specialtreatment{
        &__box{

            &__list{
                flex-direction: column;
            }
        }

    }
}