.footer{
    &__navbox{
        padding: 20px;
        margin:70px;
        position:relative;
        @media (max-width: 910px)  {
            padding:0px;
            margin:40px 20px;
            
        }

        &--line{
            padding:80px 0;
            border-bottom:solid #E2E2E2 1px;
            border-top:solid #E2E2E2 1px;

            @media (max-width: 910px)  {
                padding:20px;
            }
        }

    }

    &__nav{

        &-list{
            display: flex;
            justify-content: center;
            gap:35px;
            @media (max-width: 910px)  {
                flex-direction: column;

                li{
                    text-align: left;
                }
                
            }
        }

        &-item{
            list-style: none;
        }


    }

    &__copywrite{
        padding:48px 0;
        background: #E3F0F2;

    }

}