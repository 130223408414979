.access {
    &__flex{
        display: flex;
        justify-content: center;
        gap:55px;
        margin-bottom: 80px;
    }
    &__contents{
        &__list{
            border-radius: 66px;
            background: #E9F7F9;
            padding: 20px 40px; // 必要に応じて調整
            overflow: hidden; // border-
            margin-bottom: 40px;
            li{
                display: flex;
                justify-content: space-between;
                gap:45px;
                border-bottom: 1px solid black; // 各行に線を引く
                padding: 25px 40px;
                position: relative;
                &:last-child{
                    border-bottom: none;
                }
                span{
                    display: flex;
                    align-items: center;
                    &:first-child{
                        justify-content: center; 
                        // margin-right: 53px;
                        width: 105px;
                    }
                    &:last-child{
                        flex-grow: 1; // 右のspanを可能な限りのスペースで伸ばす
                        justify-content: left;
                        align-items: flex-start;
                    }
                }
            }
            &--AlignLeft{
                flex-direction: column;
                gap: 0;
                p{
                    margin:0;
                }
            }
        }
        &__text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-top: 20px;
            p{
                margin:0;
            }
            &__title{
                display: flex;
                align-items: center;
                justify-content: left;
                img{
                    width:auto;
                    margin-top: 8px;
                }
            }
        }
    }  
    &__map {
        padding-top: 20px;
    }
}