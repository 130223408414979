.recruit{

    .keyvisual{

        .animation{
            margin-left:auto;
            margin-right: 0;
            text-align: right;
            overflow: hidden;
            position: relative;
            width: 1113px;
            height: 687px;
            margin-bottom:120px;

            &__image{
                width: 100%;
                height: auto;
                position: absolute;
                top: 0;
                left: 0;
                animation: clip-path-animation 12s infinite alternate; 
                clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
            }
        }

        @keyframes clip-path-animation {

            0%{
                clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
            }
    
            50%{
                clip-path: path("M185.649 427.5C3.03502 335 -8.13197 113.667 3.03469 0L1112.03 2.5V688.5C997.534 638 825.549 721.591 670.535 688.5C438.649 639 405.876 539.052 185.649 427.5Z");
            }
    
            100%{
                clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
            }
    
    
    
        }
    }

    .information{

        &__container{
            display: flex;
            justify-content: center;
            gap:40px;
            padding-top:80px;

            &__img{
                max-width:550px;

                img{
                    width:100%;
                }
            }

            &__text{
                font-size: 20px;
                color: #000;
                letter-spacing: 6.2px;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 20px 0;
            }

        }
    }

    .requirements{

        &__box{
            background: #E9F7F9;
            padding:115px 55px 75px;
            margin:190px 50px 100px;
            border-radius: 60px;

            &--white{
                background: #FFF;
                padding:55px;
                border-radius: 20px;
                margin:80px 0;

                .table{
                    border: 2px solid #008FB5;
                    border-radius: 15px;
                    overflow:hidden;
                    font-size: 20px;

                    &-row{
                        display: flex;
                        border-bottom:1px solid #B6B6B6;
                        &:last-child{
                            border-bottom:none;
                        }
                    }

                    &-header{
                        background: #E9F7F9;
                        width:260px;
                        letter-spacing: 10px;
                        padding:20px 0 20px 30px;
                        display: flex;
                        align-items: center;
                        justify-content: left;
                        color: #008FB5;
                        
                    }

                    &-cell{
                        padding:20px;
                        color: #008FB5;
                        text-align: left;
                        letter-spacing: 15px;
                    }
                }

            }
        }
    }

}

@media screen and (max-width:910px){
    .recruit{
        .keyvisual{

            .animation{
                width: 100%;
                height: 687px;
                margin-bottom:0;
    
                &__image{
                    width: 100%;
                    height: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    animation: clip-path-animation 12s infinite alternate; 
                    clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
                }
            }
    
            @keyframes clip-path-animation {
    
                0%{
                    clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
                }
        
                50%{
                    clip-path: path("M185.649 427.5C3.03502 335 -8.13197 113.667 3.03469 0L1112.03 2.5V688.5C997.534 638 825.549 721.591 670.535 688.5C438.649 639 405.876 539.052 185.649 427.5Z");
                }
        
                100%{
                    clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
                }
        
        
        
            }
        }


        .information{

            &__container{
                flex-direction: column;
                align-items: center;
            }
        }

        .requirements{

            &__box{
                padding:115px 55px 75px;
                margin: 20px 20px 20px;
            }
        }
    }
}

@media screen and (max-width:500px){
    .recruit{

        .keyvisual{
    
            .animation{
                width: 100vw;
                height: 50vh;
                margin-bottom:0px;
    
                &__image{
                    width: 100%;
                    height: auto;
                    position: absolute;
                    top: 0;
                    left: 0;
                    animation: clip-path-animation 12s infinite alternate; 
                    clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
                }
            }
    
            @keyframes clip-path-animation {
    
                0%{
                    clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
                }
        
                50%{
                    clip-path: path("M185.649 427.5C3.03502 335 -8.13197 113.667 3.03469 0L1112.03 2.5V688.5C997.534 638 825.549 721.591 670.535 688.5C438.649 639 405.876 539.052 185.649 427.5Z");
                }
        
                100%{
                    clip-path: path("M158 499C7.60002 371 -7.66665 111.667 3.50001 -2L1112.5 0.5V686.5C998 636 829.5 662 671 663.5C512.5 665 346 659 158 499Z");
                }
        
        
        
            }
        }
    
        .information{
    
            &__container{
                display: flex;
                justify-content: center;
                gap:10px;
                padding:10px 20px 10px;
    
                &__text{
                    font-size: 20px;
                    color: #000;
                    letter-spacing: 6.2px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    padding: 20px 0;
                }
    
            }
        }
    
        .requirements{
    
            &__box{
                background: #E9F7F9;
                padding:10px 10px 30px;
                margin:10px 20px 10px;
                border-radius: 15px;
    
                &--white{
                    background: #FFF;
                    padding:15px;
                    border-radius: 20px;
                    margin:20px 0;
    
                    .table{
                        border: 2px solid #008FB5;
                        border-radius: 15px;
                        overflow:hidden;
                        font-size: 20px;
    
                        &-row{
                            display: flex;
                            border-bottom:1px solid #B6B6B6;
                            flex-direction: column;
                            &:last-child{
                                border-bottom:none;
                            }
                        }
    
                        &-header{
                            background: #E9F7F9;
                            width:260px;
                            letter-spacing: 10px;
                            padding: 10px 0 15px 20px;
                            display: flex;
                            align-items: center;
                            justify-content: left;
                            color: #008FB5;
                            
                        }
    
                        &-cell{
                            padding:20px;
                            color: #008FB5;
                            text-align: left;
                            letter-spacing: 15px;
                        }
                    }
    
                }
            }
        }
    
    }
}