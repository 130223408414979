.medical-top{

    .key-visual {
        margin:35px 0;
        &__flex{
            display:flex;
        }
        &__links {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            padding:105px 0 105px 45px;
            a {
                padding: 20px 50px;
                white-space: nowrap;
                font-size: 20px;
            }
        }
    
        &__visual {
            border-radius: 54px 0 0 54px;
            overflow: hidden; 
            width: 100%;
        }
    }

    .news {
        margin:102px 92px;
        padding:32px 80px 75px;
        
        &__contents{
          list-style: none;
          background: #E9F7F9;
          padding:45px;
          border-radius: 20px;
  
          li{
              display:flex;
              justify-content: center;
              gap:150px;
              border-bottom:solid 1px #000;
              margin-bottom:40px;
  
          }
  
        }
      }

    &__departments{
        background: #E7ECF0;
        padding:55px 0 90px;
        position: relative;
        z-index: 1;
    }

    &__buttonbox{
        display: grid;
        grid-template-columns: repeat(3, minmax(0, 205px)); 
        gap: 20px;  /* 各アイテムの間隔。必要に応じて調整可能 */
        z-index:100;
        justify-content: center;
        margin:auto;
        position:relative;
    }

    &__img{
        z-index:1;
        &--topleft{
            position: absolute;
            top: 0;
            left: 0;
            width:auto;
        }
        &--bottomright{
            position: absolute;
            bottom: 0;
            right: 0;
            width:auto;
        }

    }

    &__greetings{
        padding:60px 0 50px;
    }

    &__profilebox{
        display:flex;
        justify-content: center;
        gap:50px;


        img{
            width:100%;
        }
    }

    &__profile-table{
        text-align:left;
        border:solid 1px #008FB5 ;
        border-radius: 10px;
        max-width:520px;
        

        tr:nth-child(1),
        tr:nth-child(2),
        tr:nth-child(3) {
          td {
            border-bottom: 1px solid #B6B6B6;
          }
        }

        td{
        }

        &--left{
            background: #E9F7F9;
            color: #008FB5;
        }
        &--right{
            background: #fff;
            padding-right: calc(120px * (100vw - 940px) / (1440px - 940px));

            @media (max-width: 940px) {
                padding-right: 0;
              }
            
              @media (min-width: 1440px) {
                padding-right: 120px;
              }

        }
    }

    &__profile-img{
        max-width:520px;
    }

    &__facility-grid{
        display:grid;
        grid-template-rows: 175px 60px 175px;
        grid-template-columns: repeat(4, 1fr);
        gap:25px;
        max-width:900px;
        justify-content: center;
        align-items: center;
        margin:auto;
        position:relative;
        

        img{
            width:100%;
            height: 100%;
            object-fit: cover;
        }

        &--1{
            order:1;
            grid-column: 1 / 2;
            grid-row: 1 / 3;
            z-index: 100;
            
        }

        &--2{
            order:2;
            grid-column: 1 / 2;
            grid-row: 3 / 4;
            z-index: 100;
        }

        &--3{
            order:3;
            grid-column: 2 / 3;
            grid-row: 1 / 2;
            z-index: 100;
        }


        &--4{
            order:4;
            grid-column: 2 / 4;
            grid-row: 2 / 4;
            z-index: 100;
        }

        &--5{
            order:5;
            grid-column: 3 / 4;
            grid-row: 1 / 2;
            z-index: 100;
        }


        &--6{
            order:6;
            grid-column: 4 / 5;
            grid-row: 1 / 4;
            z-index: 100;
        }


    }

    &__facility-vector{
        width: calc(940px + (100 * (100vw - 940px) / (1440 - 940)));
        max-width:1040px;
        min-width:940px;
        background: #76CED5;
        height: 490px;
        position:absolute;
        top:33px;
        z-index:1;
        border-radius: 116px 0px;
        left: 50%;
        transform: translateX(-50%);
    }
}

@media (max-width: 910px)  {
    .medical-top{

        .key-visual{

            &__visual{
                width:92%;
            }
        }

        .news{
            margin: 20px 20px;
            padding: 1px 0px 0px;

            &__contents{
                padding:25px;
                li{
                    gap:20px;

                    span{
                        font-size: 14px;
                    }
                }
            }
        }

        &__departments{

        }

        &__buttonbox{
            grid-template-columns: repeat(2, minmax(0, 170px));
        }

    }
    .medical-top__img--topleft,.medical-top__img--bottomright{
        display: none;
    }
}