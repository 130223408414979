// Top Page
// ブレイクポイント　
// タブレット940
// モバイル　425

.top-page {
    .key-visual {
        margin:35px 0;
        &__flex{
            display:flex;
        }
        &__links {
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            padding:105px 0 105px 45px;
            a {
                padding: 20px 50px;
                white-space: nowrap;
                font-size: 20px;
            }
        }
    
        &__visual {
            border-radius: 54px 0 0 54px;
            overflow: hidden; 
            width: 100%;
        }
    }

    .image-links {
      margin:130px 0;
        &__wrapper{
        display:flex;
        padding:20px 110px;
        justify-content:space-between;
        gap:250px;

        p{
            padding-bottom:20px;
        }

        }

        &__medical {
            display:flex;
            flex-direction:column;
            justify-content: space-between;
            flex: 1;

        img {
            width:100%;
            height: auto;
        }
        }
    
        &__dental {
            display:flex;
            flex-direction:column;
            justify-content: space-between;
            flex: 1;
    
            img {
                width:100%;
                height: auto;
            }
        }
        &__button{

        }
    }

    .free-section {
        padding:0 45px;
        &__concept{
            background-color: #E9F7F9;
            padding:110px 70px;
            border-radius: 115px 0px;
            display:flex;
            flex-direction: column;
            justify-content: space-between;
            gap:90px;
        }
        &__wrapper{
            display:flex;
            background-color: #FFF;
            justify-content: center;
            align-items: center;
            gap:65px;
            padding: 60px 43px;
        }
        &__text {
            max-width: 350px;
        }
          
        &__image {
            max-width: 350px;
            
            
                img {
                    border-radius: 55px;
                }
        }
    }
  
    .news {
      margin:102px 92px;
      padding:32px 80px 75px;
      
      &__contents{
        list-style: none;
        background: #E9F7F9;
        padding:45px;
        border-radius: 20px;

        li{
            display:flex;
            justify-content: center;
            gap:150px;
            border-bottom:solid 1px #000;
            margin-bottom:40px;

        }

      }
    }
  
    .recruitment {
      padding:20px 167px 154px;

        &__image{
            width:450px;
        }

      &__flex{
        display: flex;
        justify-content: center;
        align-items: center;
        gap:66px;
        margin-top:53px;
      }

      &__text{
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 100px;
      }
    }


  
    .access {
        &__flex{
            display: flex;
            justify-content: center;
            gap:55px;
            margin-bottom: 80px;
        }
        &__contents{
            &__list{
                border-radius: 66px;
                background: #E9F7F9;
                padding: 20px 40px; // 必要に応じて調整
                overflow: hidden; // border-
                margin-bottom: 40px;
                li{
                    display: flex;
                    justify-content: space-between;
                    gap:45px;
                    border-bottom: 1px solid black; // 各行に線を引く
                    padding: 25px 40px;
                    position: relative;
                    &:last-child{
                        border-bottom: none;
                    }
                    span{
                        display: flex;
                        align-items: center;
                        &:first-child{
                            justify-content: center; 
                            // margin-right: 53px;
                            width: 105px;
                        }
                        &:last-child{
                            flex-grow: 1; // 右のspanを可能な限りのスペースで伸ばす
                            justify-content: left;
                            align-items: flex-start;
                        }
                    }
                }
                &--AlignLeft{
                    flex-direction: column;
                    gap: 0;
                    p{
                        margin:0;
                    }
                }
            }
            &__text{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-top: 20px;
                p{
                    margin:0;
                }
                &__title{
                    display: flex;
                    align-items: center;
                    justify-content: left;
                    img{
                        width:auto;
                        margin-top: 8px;
                    }
                }
            }
        }  
        &__map {
            padding-top: 20px;
        }
    }
  }

  @media (max-width: 910px) {
    .for-sp{
        .key-visual{

            &__visual{
                margin-left:36px;
            }
        }

        .image-links{

            &__wrapper{
                padding:0 10px;
                flex-direction:column;
                gap:50px;
            }
        }

        .free-section{
            padding:0;

            &__concept{
                padding:50px 30px;
                gap:40px;
            }

            &__wrapper{
                flex-direction: column;
                padding: 20px 20px;

                &:last-child{
                    flex-direction: column-reverse;
                }
            }

        }

        .news{
            margin:30px 0 0;
            padding:0 20px;

            &__contents{
                padding: 30px 10px 20px;

                li{
                    gap:40px;
                }
            }
        }

        .recruitment{
            padding:0 20px 30px;

            &__image{
                width:auto;
            }

            &__flex{
                flex-direction: column;
                gap:0;
            }

            &__text{
                gap:0;
            }
        }

        .access{
            &__contents{
                margin: 0 20px;

                &__list{

                    padding:20px 10px;
                    border-radius: 25px;
                    margin-bottom: 10px;


                    li{
                        padding:10px 10px; 
                        
                    }
                }
            }
        }

        .medical-top__profilebox{
            display: flex;
            flex-direction: column;
        }

    }
}