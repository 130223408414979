// Header.scss
.header {

    .lineicon{
        position: fixed;
        bottom: 680px;
        right: 0;
        z-index: 100;

        img{
            width: 65px;
        }
    }

    display:flex;
    justify-content: space-between;
    margin:35px 44px;

    img{
        width:auto;
    }
  
    &__menu-list {
      display:flex;
      list-style: none;
      gap:20px;
    }

    &__menu-item {
        position: relative;

        a{
            -webkit-transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            transition: all  0.3s ease;
        
        }

        &:hover {
            a{
                color: #008FB5;
            }

            .header__submenu-list {
                display: block;
                background-color: white;
                    a{
                        color: #000;
                        &:hover {
                            color: #008FB5;
                        }
                    }
                }
            
        }
      }

    &__submenu-box{
        padding-top:20px;
        position: absolute; 
        z-index: 100;
    }

    &__submenu-list {
        list-style: none;
        display: none;
        top: 100%;
        left:-200%;
        padding:0;
        border: 1px solid #D7D7D7;
        border-radius: 10px;
        width: 370px;
        text-align:left;
        padding:16px 25px 22px;
        
        a{
            font-size: 16px;
        }

    }

    &__submenu-item{
        position: relative;
        padding: 10px 0;
        border-bottom: 1px solid #D3D3D3;

        &:last-child{
            border-bottom:none;
        }

    }

    .hamburger {
        cursor: pointer;
        width: 30px;
        height: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
    
        .bar {
            background-color: #333;
            height: 4px;
            width: 100%;
            border-radius: 2px;
            transition: 0.5s;
        }
    }
    
    .navigation {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
    
    .hamburger {
        z-index: 100;
        position: fixed;
        top: 32px;
        right: 10px;

        .bar{
            background: #008FB5;
        }
        &.is-active {
            .bar:nth-child(1) {
                transform: rotate(-45deg) translateY(12px);
            }
    
            .bar:nth-child(2) {
                opacity: 0;
            }
    
            .bar:nth-child(3) {
                transform: rotate(45deg) translateY(-12px);
            }
        }
    }

  }


  @media (max-width: 910px){
    .header{
        display: flex;

        &__menu-item{
            a{
                color:white;
            }
        }

        &__submenu-box{
            
        }

        .overlay {
            display: none;
            position: fixed;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.3); /* 透明度を持つ黒背景 */
            z-index: 1; /* navigationよりも下になるように */
        }

        .navigation {
            position: fixed; /* 画面の右端に固定 */
            top: 118px;
            right: -100%; /* 画面の外に隠れている */
            width: 220px; /* 任意の幅を指定 */
            height: 50vh; /* 画面の高さ全体 */
            background-color: #76CED5; /* 任意の背景色 */
            transition: right 0.5s ease-out; /* スムーズなアニメーションのためのトランジション */
            z-index: 2;
            border-radius: 50px 0;
        }

        .navigation.is-active {
            right: 0; /* 画面の右端に表示 */
        }

        .navigation ul {
            list-style-type: none;
            padding: 0;
            margin: auto;
        }
        .navigation li {
            padding: 10px;
            color: white;
        }
    }

  }
  