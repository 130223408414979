.dentalpractice{
    background: #E9F7F9;
    padding:130px 95px;

    .flexwrapper{
        display:flex;
        flex-direction: column;
        gap:60px;

        &__contents{
            display: flex;
            gap:20px;

            .imgwrapper{
                max-width: 510px;
                max-height: 310px;
                border-radius: 20px;
    
                img{
                    width:100%;
                    border-radius: 20px;
                    height: 100%;
                }
            }

            .text-box{
                display:flex;
                flex-direction: column;
                justify-content: space-between;
                background: #FFF;
                border-radius: 20px;
                padding:15px 10px 25px;
                text-align: left;
                h2,p{
                    padding:0 10px;
                }
            }
            
            .buttons{
                display: flex;
                justify-content: space-between;
                &.double{
                    justify-content: center;
                    gap:40px;
                }

                &--three{
                    display: flex;
                    justify-content: center;
                    align-items: center; 
                    padding:20px 30px;
                    align-items: center;
                    text-align:center;
                    margin: auto;
                    border-radius: 20px;
                    height:20px;
                }

                &--two{
                    height:20px;
                    padding:20px 30px;
                    border-radius: 20px;
                }
            }
        }
    }

}

@media (max-width: 910px){
    .dentalpractice{
        padding: 40px 20px;
    }
    .flexwrapper{
        &__contents{
            flex-direction: column;
        } 
    }
}